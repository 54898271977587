.container {
    margin-top: 0.5rem;
    font-size: small;
    font-family: 'Poppins', sans-serif;
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  
  .drawerContent {
    width: 70vw;
    height: 100%;
    padding: 0 !important;
  }
  
  /* Three-column layout if flow sessions exist */
  .mainContainerThree {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    height: 100%;
    & > div {
      width: 100%;
      height: 100%;
      padding: 20px;
      overflow: hidden;
    }
    & > .flowChild {
      border-right: 0.5px solid #afafaf;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & > .leftChild {
      border-right: 0.5px solid #afafaf;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & > .rightChild {
      display: flex;
      flex-direction: column;
      gap: 30px;
      & > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & > .infoDiv {
        display: flex;
        flex-direction: column;
        gap: 20px;
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.875rem;
          line-height: 1.25rem;
          & > div:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
  
  /* Two-column layout if no flow sessions */
  .mainContainerTwo {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100%;
    & > div {
      width: 100%;
      height: 100%;
      padding: 20px;
      overflow: hidden;
    }
    & > .leftChild {
      border-right: 0.5px solid #afafaf;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & > .rightChild {
      display: flex;
      flex-direction: column;
      gap: 30px;
      & > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & > .infoDiv {
        display: flex;
        flex-direction: column;
        gap: 20px;
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.875rem;
          line-height: 1.25rem;
          & > div:first-child {
            font-weight: 600;
          }
        }
      }
    }
  }
  
  .conversationDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    & > .Convo {
      padding: 14px 20px;
      max-width: 80%;
      width: fit-content;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0;
      color: #000;
      border-color: rgba(125, 135, 155, 0.2);
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      position: relative;
      & > div {
        margin-top: auto;
        color: rgba(8, 20, 43, 0.6);
      }
    }
    & > .AgentTranscript {
      margin-right: auto;
      background-color: rgba(135, 155, 175, 0.05);
    }
    & > .UserTranscript {
      margin-left: auto;
      background-color: rgba(135, 145, 165, 0.1);
    }
  }
  
  .OverviewContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > .infoContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &.callStatus {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & > div {
          width: fit-content;
        }
      }
      & > .informationDiv {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }
    }
  }
  
  .summaryInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
      padding: 0.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0;
      display: flex;
      flex-direction: column;
      gap: 6px;
      & > h6 {
        font-weight: 500;
      }
      & > div {
        font-weight: 400;
      }
    }
  }
  
  .msInfo {
    position: absolute;
    bottom: 0%;
    transform: translateY(50%);
  }
  
  .headersDataCollection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .specialTextClass {
    --text-color: '41, 112, 188';
  }
  
  .AudioPlayer {
    width: 100%;
    padding: 30px 0;
  }
  
  /* Nested Drawer styles */
  .nestedDrawer {
    width: 60vw;
  }
  .nestedDrawerContent {
    width: 60vw;
  }
  
  .shifted {
    transition: margin-right 0.3s ease;
    margin-right: 60vw; // Adjust based on your nested drawer width
  }
  