.container {
  padding: 1.5rem;
  font-family: Roboto;
}

.container3 {
  padding: 1.5rem;
  font-family: Roboto;
  background-color: #fff;
  margin: 1rem;

  .title2 {
    color: #111827;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-align: center;
  }

  &.addOnStyling {
    display: flex;
    align-items: center;
    gap: 10px;

    & > .title2 {
      margin: 0;
    }
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1.5rem;
}
.title {
  font-size: 1.4rem;
  font-weight: 500;
}
.sub {
  display: flex;
  align-items: center;
  gap: 10px;

  & > .deleteButton {
    color: #fff;
    text-align: center;
    width: fit-content;
    margin-top: 20px;
    padding: var(--8, 8px) 32px var(--8, 8px) 31px;
    border-radius: 10px;
    background: red; 
    cursor: pointer;
  }
}

.subtitle {
  margin-top: 1rem;
  font-size: 1.2rem;
  span,
  a {
    cursor: pointer;
    font-weight: 500;
    color: #2f86ff;
  }
}

.chatboxContainer {
  height: 70vh;
  width: 100%;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  padding: 1rem;
  border-radius: 14px;
}
.chatBoxMessages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding: 1rem 1.75rem;
  border-radius: 14px;
  //   background-color: #f5f5f5;
}
.messageSent {
  align-self: flex-end;
  background-color: #2f86ff;
  margin-bottom: 1rem;
  max-width: 70%;
  padding: 1rem;
  color: #fff;

  position: relative;
  border-radius: 13px;
}
.messageSentTime {
  font-size: 0.8rem;
  color: #fff;
  text-align: right;
  margin-left: 0.5rem;
}
.messageSentExtra {
  display: flex;
  margin-top: 0.5rem;
  justify-content: flex-end;
  align-items: center;
}
.messageReceived {
  align-self: flex-start;
  background-color: #ddefff;
  margin-bottom: 1rem;
  max-width: 70%;
  padding: 1rem;
  color: #333;
  border-radius: 13px;
  position: relative;

  &.warningMessage {
    color: #891B1B;
    background-color: #FFEBEB;
  }
}
.messageReceivedTime {
  font-size: 0.8rem;
  color: #333;
  text-align: right;
  margin-left: 0.5rem;
}
.messageReceivedExtra {
  display: flex;
  margin-top: 0.5rem;
  justify-content: flex-end;
  align-items: center;
}
.messageAudioButton {
  position: absolute;
  top: 0rem;
  right: -1.5rem;
  cursor: pointer;
}
.messageAudioButton svg {
  width: 1.25rem;
}
.summaryContainer {
  font-family: Roboto;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 35rem;
  border-radius: 14px;
  padding: 1rem;
  background-color: #fff;
}

.screeningQuestion {
  font-family: Roboto;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 25rem;
  border-radius: 14px;
  padding: 1rem;
  background-color: #fff;
}

.CandidateStatus{
  color: #fff;
  display: flex;
  width: fit-content;
  margin-top: 20px;
  padding: var(--8, 8px) 32px var(--8, 8px) 31px;
  // justify-content: center;
  // align-items: center;
  gap: 10px; 
  border-radius: 10px;
  background: var(--CTA-Green, #0f0f0f); 
}

.ButtonStatus {
  display: flex;
  width: fit-content;
  margin-top: 20px !important;
  padding: var(--8, 8px) 32px var(--8, 8px) 31px;
  // justify-content: center;
  // align-items: center;
  gap: 10px; 
  border-radius: 10px;
  background: var(--CTA-Green, #0f0f0f); 
  border: 2px solid #155724;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #d4edda;

  &.buttonBlue {
    background-color: #2f86ff52;
    border-color: #2f86ff;
    color: #2f86ff;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
  }

  &.buttonPurple {
    background-color: #c02ad138;
    border-color: #6d208e;
    color: #6d208e;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
  }
}

.summaryTitle {
  border-radius: 14px;
  margin-bottom: 1rem;

  color: #111827;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-align: center;
}

.summaryText {
  display: flex;
  flex-direction: column;

  // align-items: start !important;
  // line-height: 1.7;
  height: 100%;
}
.title2 {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.userImages {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.userImg {
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  width: 12rem;
  border-radius: 6px;
}
.interviewScores {
  display: flex;
  gap: 1rem;

  
  & > .visualScoreing {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;

    width: 100%;

    & > * {
        & > h4 {
            color: #111827;
            text-align: center;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
        }

        & > div {
            height: 100%;
            justify-content: center;
        }
    }
    & > .score {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > div {
            padding: 20px;
            width: 200px;
            height: 200px;
        }
    }
    & > .subRating {
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding-inline: 60px;

      & > .subRatinglist {
          display: flex;
          flex-direction: column;

          gap: 10px;

          & > .ratingData {
              & > .info {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  & > * {
                      color: #111827;
                      text-align: center;
                      font-family: Poppins;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 300;
                      line-height: 20px; /* 200% */

                      margin: 0;
                  }
              }

              & > .customProgressBar {
                  height: 10px;
                  background-color: rgba(204, 204, 204, 1);

                  &.success > div {
                      background-color: rgba(104, 187, 98, 1);
                  }

                  &.warning > div {
                      background-color: rgba(243, 222, 84, 1);
                  }

                  &.danger > div {
                      background-color: rgba(247, 101, 118, 1);
                  }
              }
          }
      }
    }
  }
}
.interviewScoreContainer {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: #fafafb;
  display: flex;

  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
}
.interviewScoreTitle {
  font-size: 1rem;
  margin-top: 0.25rem;
  font-weight: 600;
  color: #2f86ff;
  margin-bottom: -1rem;
}
.interviewScore {
  font-size: 1.5rem;
  font-weight: 500;
}
.interviewScoreCategoryMain {
  background-color: #ddefff;
  padding: 1rem;
  padding-top: 0.5rem;
  margin-top: 1rem;
  border-radius: 14px;
}
.interviewScoreCategoryTitle {
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #2f86ff;
  margin-top: 1rem;
  display: flex;

  &.spanDiv {
    width: fit-content;
    display: flex;
    align-items: center;

    gap: 10px;
  }
}
.interviewScores2 {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  align-items: start;
  
  & > .questions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;
    margin-bottom: 15px;

    & > h3 {
      color: #111827;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }

    & > .blueInfoDiv {
      & > .questionSpanDiv {
        display: flex;
        gap: 10px; 
        width: fit-content;
      }
    }
  }
}

.skillRatingContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.CheatingInfo {
  display: flex;
  flex-direction: column;

  gap: 15px;

  & > .blueInfoDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 10px;

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
}

.SkillScoreList {
  display: flex;
  flex-direction: column;

  gap: 20px;

  & > .Skill {
    display: flex;
    flex-direction: column;

    gap: 10px;

    & > h3 {
        display: flex;
        gap: 10px;

        align-items: center;

        color: #111827;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }

    & > .scoreAndReview {
        display: flex;
        gap: 15px;

        & > .Score {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > h4 {
                color: #111827;
                text-align: center;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 166.667% */
            }

            & > .circularBar {
                width: 100px;
                height: 100px;
            }
        }

        & > .Review {
            display: flex;
            flex-direction: column;

            gap: 10px;
            
            padding: 15px 25px;
            width: 100%;

            & > div {
                width: fit-content;
                gap: 20px;
            }

            & > p {
                color: #4B5563;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 19.5px; /* 162.5% */

                margin: 0;
            }
        }

        @media (max-width: 500px) {
          flex-direction: column;

          & > .Score > .circularBar {
            width: 150px;
            height: 150px;
          }
        }
    }
  }
}

.blueInfoDiv {
  border-radius: 8px;
  border: 0.5px solid #D3DAE5;
  opacity: 0.9;
  background: #EAF2FA;
  padding: 14px 12px;

  display: flex;
  flex-direction: column;

  gap: 10px;

  width: 100%;

  & > * {
    margin: 0 !important;
  }

  & > .danger {
    color: #F76576;
  }

  & > h3 {
      color: #111827;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      margin: 0;
  }

  & > p {
      color: #4B5563;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.5px; /* 162.5% */

      margin: 0;
  }
}

.spanDiv {
  display: flex;
  justify-content: space-between;

  align-items: center;
  
  border-radius: 6px;
  background: #FFF;

  color: #4B5563;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.5px; /* 162.5% */

  padding: 10px;

  & > * {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 5px;
      min-width: 50px;
  }

  
  & > .success {
    border-radius: 4px;
    background: #F3FFF2;

    color: #1B891B;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  & > .warning {
    border-radius: 4px;
    background: #fdfff2;

    color: #FFC371;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  & > .danger {
    border-radius: 4px;
    background: #FFEBEB;

    color: #891B1B;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; /* 162.5% */
  }
}

.pronunciationAssessmentStyles {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;


    & > *:first-child {
      width: 150px;
      height: 150px;
    }

  }
}


.ratingGrid {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .blueInfoDiv {
      display: flex;
      flex-direction: column;

      gap: 10px;

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > h3 {
          color: #111827;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
    
          margin: 0;
        }
        
        & > .spanDiv {
            width: fit-content;
            gap: 20px;
        }
      }

  }
}

.progressContainer {
  width: 100%;
  height: 10px;
  background-color: #e9ecef; /* Light grey background */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progressBar {
  height: 100%;
  transition: width 0.5s ease-in-out; /* Smooth animation */
  border-radius: inherit; /* Match the container's border */

  &.success {
    background-color: #68bb62;
  }

  &.warning {
    background-color: #F3DE54;
  }

  &.danger {
    background-color: #F76576;
  }
}

.interviewScoreSno {
  color: #111827;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
.interviewScoreLabel {
  color: #333;
  font-size: 1.1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
    background-color: #ddefff;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.interviewScoreValue {
  margin-top: 0.5rem;
  color: #444;
  font-size: 1.05rem;
}

.jobModal {
  font-family: Roboto;
  width: 45rem;
  & > div {
    width: 100%;
    margin: 0;
    max-height: 90vh;
    overflow-y: auto;
  }
}
.jobModalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.deleteModal {
  font-family: Roboto;
  width: 30rem;

  background: #fafafb;
  padding: 30px;

  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 500;

  display: flex;
  flex-direction: column;

  gap: 10px;

  & > .buttonGrp {
    display: flex;
    gap: 10px;

    justify-content: end;

    & > * {
      padding: 5px 10px;
      background-color: #2f86ff;
      border-radius: 10px;
      color: #ddefff;

      cursor: pointer;

      &:first-child {
        background-color: red;
      }
    }
  }
}

// Add these styles to InterviewDetails.module.scss

.chartContainer {
  width: min(700px, 100%);
  // height: 400px; // Adjust as needed
  position: relative;
}

.behaviour {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  flex-wrap: wrap;

  & > * {
    flex-basis: 0;
    flex-grow: 1;
  }
}
.proctoringContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.proctoringItem {
  display: flex;
  justify-content: space-between;
  font-size: 1.05rem;
}

.proctoringLabel {
  font-weight: 500;
  color: #333;
}

.proctoringValue {
  font-weight: 500;
  color: #2f86ff;
}

.addOnsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.addOnItem {
  background-color: #ddefff;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-weight: 500;
  color: #2f86ff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.AudioPlayer {
  width: 100%;
  max-width: 70rem;
  padding: 30px 0;
}

.VideoPlayer {
  width: 100%;
  height: 30vh;
  padding: 20px 0 0;
}

.conversationDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  overflow: scroll;
  padding: 3px;

  &::-webkit-scrollbar {
    display: none;
  }

  & > .Convo {
      padding: 14px 20px;
      max-width: 80%;
      width: fit-content;
      font-size: .875rem;
      line-height: 1.25rem;
      letter-spacing: 0;
      color: #000;
      border-color: rgba(125, 135, 155, .2);
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      gap: 5px;

      position: relative;

      & > div {
          margin-top: auto;
          color: rgba(8, 20, 43, .6);
      }
  }

  & > .AgentTranscript {
      margin-right: auto;
      background-color: rgba(135, 155, 175, .05);
  }
  
  & > .UserTranscript {
      margin-left: auto;
      background-color: rgba(135, 145, 165, .1);
  }
}
// SessionCard.module.scss
.sessionContainer {
  width: 100%;
  max-height: 70vh;
  
  overflow: scroll;
  // max-width: 64rem;
  // margin: 0 auto;
  padding: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.card {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #f1f1f1;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.cardContent {
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.date {
  font-size: 0.875rem;
  color: #6b7280;
}

.metricsGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.metric {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #3b82f6;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .label {
    font-size: 0.875rem;
    color: #6b7280;
  }

  .value {
    font-weight: 500;
    color: #1f2937;
  }
}

.status {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.625rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;

  &.completed {
    background-color: #dcfce7;
    color: #166534;
  }

  &.incomplete {
    background-color: #fef9c3;
    color: #854d0e;
  }
}

.terminationSection {
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid #f3f4f6;

  .terminationLabel {
    font-size: 0.875rem;
    color: #6b7280;
  }

  .terminationValue {
    font-weight: 500;
    color: #1f2937;
  }
}

.container {
  margin-top: 0.5rem;
  font-size: small;
  font-family: 'Poppins', sans-serif;
  padding: 1.5rem;
  // background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.drawerContent {
  width: 50vw;
  height: 100%;
  padding: 0 !important;
}

.mainContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;

  & > div:first-child {
      border-right: .5px solid #afafaf;
      overflow-y: scroll;

      &::-webkit-scrollbar {
          display: none;
      }
  }

  & > div {
      width: 100%;
      height: 100%;
      padding: 20px;
      overflow: hidden;
  }

  & > .rightChild {
      display: flex;
      flex-direction: column;

      gap: 30px;

      & > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
      }

      & > .infoDiv {
          display: flex;
          flex-direction: column;
          gap: 20px;

          & > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: .875rem;
              line-height: 1.25rem;
              
              & > div:first-child {
                  font-weight: 600;
              }
          }
      }
  }
}

.conversationDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  & > .Convo {
      padding: 14px 20px;
      max-width: 80%;
      width: fit-content;
      font-size: .875rem;
      line-height: 1.25rem;
      letter-spacing: 0;
      color: #000;
      border-color: rgba(125, 135, 155, .2);
      border-radius: 1rem;
      display: flex;
      justify-content: space-between;
      gap: 5px;

      position: relative;

      & > div {
          margin-top: auto;
          color: rgba(8, 20, 43, .6);
      }
  }

  & > .AgentTranscript {
      margin-right: auto;
      background-color: rgba(135, 155, 175, .05);
  }
  
  & > .UserTranscript {
      margin-left: auto;
      background-color: rgba(135, 145, 165, .1);
  }
}

.OverviewContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .infoContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &.callStatus {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          & > div {
              width: fit-content;
          }
      }
  
      & > .informationDiv {
          font-size: .875rem;
          line-height: 1.25rem;
          letter-spacing: 0;
      }
  }
}

.summaryInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > div {
      padding: .5rem;
      font-size: .875rem;
      line-height: 1.25rem;
      letter-spacing: 0;

      display: flex;
      flex-direction: column;
      gap: 6px;

      & > h6 {
          font-weight: 500;
      }

      & > div {
          font-weight: 400;
      }   
  }
}

.msInfo {
  position: absolute;
  bottom: 0%;
  transform: translateY(50%);
}

.headersDataCollection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.specialTextClass {
  --text-color: '41, 112, 188'
}

.AudioPlayer {
  width: 100%;
  padding: 30px 0;
}

.infoContainer {
  
  & > .infoDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .9rem;
        line-height: 1.25rem;
        
        & > div:first-child {
          font-weight: 600;
          display: flex;
          gap: 10px;
          align-items: center;
        }

        & > div:nth-child(2) {
          text-align: right;
        }
    }
  }
}

.battery {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  border: 2px solid #000;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 8px;
  vertical-align: middle;
}

/* The battery terminal */
.battery:after {
  content: '';
  position: absolute;
  right: -6px;
  top: 6px;
  width: 4px;
  height: 12px;
  background: #000;
  border-radius: 1px;
}

.batteryFill {
  height: 100%;
  transition: width 0.3s ease;
}

.batteryText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  pointer-events: none;
}

.topEndSection {
  display: flex;
  align-items: center;
  gap: 10px;
}

.VideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-height: 70vh;

  & > video {
    width: 100%;
    max-height: 64vh;
  }
}


.sessionVideoContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  padding-block: 20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & > video {
    border-radius: 20px;
    cursor: pointer;

    &.active {
      border: 2px solid #2f86ff;
    }
  }
}

.videoAtr {
  height: 100% !important;
  width: auto !important;
}

.InfoButton {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  overflow: hidden !important;

  & img { 
    width: 30px;
    height: 30px;
  }
}