.successDiv {
    border: 2px solid #155724;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    background-color: #d4edda !important;
    & * {
        color: #155724 !important;
    }
}

.warnDiv {
    border: 2px solid #d32f2f;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    background-color: #f8d7da !important;

    & * {
        color: #d32f2f !important;
    }
}