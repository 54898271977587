$primary-color: #007bff;
$background-color: #f4f4f4;
$text-color: #333;
$border-radius: 8px;

.extractor {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: $border-radius;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;


}

.title {
  font-size: 1.8rem;
  color: $primary-color;
  margin-bottom: 10px;
}

.cameraContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $background-color;
  border-radius: $border-radius;
  padding: 10px;
  margin-bottom: 20px;
}

.video {
  width: 100%;
  max-width: 500px;
  border-radius: $border-radius;
}

.controls {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

.button {
  background: $primary-color;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 2rem;
  transition: 0.3s;

  &:hover {
    background: darken($primary-color, 10%);
  }
}

.uploadButton {
  @extend .button;
  margin-top: 10px;
  width: 6rem;
  height:3rem ;
  margin-right: 2rem;

}
.extradiv{
  display: flex;
  justify-content: space-between;
  

}

.preview {
  width: 100%;
  max-width: 300px;
  margin-top: 15px;
  border-radius: $border-radius;
  border: 2px solid $primary-color;
}

.subtitle {
  font-size: 1.5rem;
  color: $text-color;
  margin-top: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: $border-radius;
  font-size: 1rem;
  transition: 0.3s;

  &:focus {
    border-color: $primary-color;
    outline: none;
  }
}

.flipButton {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px;
  transition: background 0.3s;
}

.flipButton:hover {
  background: #0056b3;
}
