.headerDiv {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
}

.interactor {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    width: 100%;

    & > * {
        width: fit-content;
    }

    & > .CommunicationType {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.selectDiv {
    & > * {
        padding: 10px;
    }
}

.Count {
    margin-left: 10px;
    color: aquamarine;
}