.mainContainer {
    display: flex;
    flex-direction: column;
    padding: 2em 3em;

    height: 100%;

    gap: 10px;

    & > .TopHeader {
        display: flex;
        gap: 30px;
    
        & > h3 {
            margin: 0;
            color: #020817;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
    
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
    
        & > .TabSwitch {
            display: flex;
            align-items: center;
            gap: 20px;
            cursor: pointer;
    
            & > * {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                // padding-inline: 10px;
                position: relative;
                width: 100px;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                color: #6C757D;
                white-space: nowrap;
                padding: 1rem;
                
                &.active {
                    color: #2369AC;
    
                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        height: 3px;
                        border-radius: 100px;
                        overflow: hidden;
    
                        background-color: #2369AC;
                        left: 0;
    
                        width: 100%;
                    }
                }
    
                &:hover {
                    color: #2369AC;
                    transition: 0.5s color;
    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        height: 3px;
                        border-radius: 100px;
                        overflow: hidden;
    
                        background-color: #2369AC;
                        left: 0;
    
                        width: 0;
    
                        animation: incWidth forwards 1s ease;
    
                        @keyframes incWidth {
                            to {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        & > .filters {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            color: #6C757D;
            white-space: nowrap;
            padding: 1rem;

            margin-left: auto;

            & > * {
                flex-shrink: 0;
            }
        }
    
        @media (max-width: 740px) {
            & > h3 {
                font-size: 16px;
            }
    
            & > .TabSwitch {
                gap: 10px;
    
                & > * {
                    font-size: 12px;
                }
            }
        }
    
        @media (max-width: 800px) {
            width: 100%;
            justify-content: space-between;
            & > .TabSwitch {
                & > * {
                    width: auto;
                }
            }
        }
    }

    & > .jobCardLists {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        & > .listView {
            width: 100%;
        }
    }
}

.switch {
    --_switch-bg-clr: #70a9c5;
    --_switch-padding: 4px; /* padding around button*/
    --_slider-bg-clr: rgba(12, 74, 110, 0.65); /* slider color unchecked */
    --_slider-bg-clr-on: rgba(12, 74, 110, 1); /* slider color checked */
    --_slider-txt-clr: #ffffff;
    --_label-padding: 1rem 2rem; /* padding around the labels -  this gives the switch it's global width and height */
    --_switch-easing: cubic-bezier(
      0.47,
      1.64,
      0.41,
      0.8
    ); /* easing on toggle switch */
    color: white;
    width: fit-content;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 9999px;
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    position: relative;
    isolation: isolate;
  }
  
  .switch input[type="checkbox"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .switch > span {
    display: grid;
    place-content: center;
    transition: opacity 300ms ease-in-out 150ms;
    padding: var(--_label-padding);
  }
  .switch::before,
  .switch::after {
    content: "";
    position: absolute;
    border-radius: inherit;
    transition: inset 150ms ease-in-out;
  }
  /* switch slider */
  .switch::before {
    background-color: var(--_slider-bg-clr);
    inset: var(--_switch-padding) 50% var(--_switch-padding)
      var(--_switch-padding);
    transition:
      inset 500ms var(--_switch-easing),
      background-color 500ms ease-in-out;
    z-index: -1;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.3),
      0 1px rgba(255, 255, 255, 0.3);
  }
  /* switch bg color */
  .switch::after {
    background-color: var(--_switch-bg-clr);
    inset: 0;
    z-index: -2;
  }
  /* switch hover & focus */
  .switch:focus-within::after {
    inset: -0.25rem;
  }
.switch:has(input:checked):hover > span:first-of-type,
.switch:has(input:not(:checked)):hover > span:last-of-type {
    opacity: 1;
    transition-delay: 0ms;
    transition-duration: 100ms;
}
/* switch hover */
.switch:has(input:checked):hover::before {
    inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding)
        45%;
}
.switch:has(input:not(:checked)):hover::before {
    inset: var(--_switch-padding) 45% var(--_switch-padding)
        var(--_switch-padding);
}
/* checked - move slider to right */
.switch:has(input:checked)::before {
    background-color: var(--_slider-bg-clr-on);
    inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding)
    50%;
}
  /* checked - set opacity */
.switch > span:last-of-type,
.switch > input:checked + span:first-of-type {
    opacity: 0.75;
}
.switch > input:checked ~ span:last-of-type {
    opacity: 1;
}

.cardDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    & > .card {
        border-radius: 10px;
        border: 1px solid #F0F1F2;
        background: #FFF;

        /* message box */
        box-shadow: 6px 4px 10.7px 0px rgba(204, 204, 204, 0.25);
        padding: 30px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        & > .title {
            color: #020817;
            font-family: 'Poppins', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; /* 155.556% */
        }

        & > .createdOn {
            color: #717885;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
        }

        & > .hirerInfo {
            color: #717885;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            margin-bottom: 10px;
            cursor: pointer;

            & > .hirerName {
                color: #2369AC;
                font-weight: 700;
                text-decoration: underline;
            }
        }

        & > .metrics {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-top: 10px;

            & > .metric {
                display: flex;
                flex-direction: column;

                & > .metricLabel {
                    color: #6C757D;
                    font-family: 'Poppins', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                }

                & > .metricValue {
                    color: #020817;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        & > .skills {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        & > .addons {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        & > .ButtonGrp {
            display: flex;
            justify-content: space-between;
            align-items: center;


            margin-top: auto;

            & > .copyButton {
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 20px;
                background-color: #FFF;
                color: #2369AC;
                border: none;
                padding: 10px 15px;

                cursor: pointer;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                border: 1px solid #2369AC;

                &:hover {
                    background-color: #1e5a8a;
                    color: #FFF;
                }

                & > svg {
                    width: 16px;
                    height: 16px;
                }
            }

            & > .viewAssessmentsButton {
                text-decoration: none;
                border-radius: 20px;
                background-color: #297bca;
                color: #FFF;
                padding: 10px 15px;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;

                &:hover {
                    background-color: #1e5a8a;
                }
            }
        }
    }

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
}

.paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    gap: 10px;

    & > .paginationCount {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: #6C757D;
    }

    & .MuiPagination-root {
        .Mui-selected {
            background-color: #2369AC;
            color: #FFF;
        }
    }

    & > .goToPageInput {
        width: 100px;
    }

    & > .goToPageButton {
        background-color: #2369AC;
        color: #FFF;
        border: none;
        padding: 8px 12px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e5a8a;
        }
    }
}

.skill {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px 5px 0 0;
    background-color: #eaf2fa;
    color: #297bca;
    font-size: 14px;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
}

.addon {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px 5px 0 0;
    background-color: #f0f1f2;
    color: #2369AC;
    font-size: 14px;
    border-radius: 20px;
    font-family: 'Poppins', sans-serif;
}


.headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .deleteIcon {
        cursor: pointer;
    }
}

.paginationDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.paginationSection {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}