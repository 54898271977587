
.section2 {
    margin: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
  }
  .sectionTitle {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  
  .sectionContent{
    overflow: auto;
    max-height: 10rem;
    scrollbar-width: thin; 
    scrollbar-color: #888 #e0e0e0; 
  }
  
  .sectionContent3{
   overflow: hidden
  }
  /* For Chrome, Edge, and Safari */
  
  .sectionContent2 {
    overflow: auto;
    height: 3.3rem;
    scrollbar-width: thin; 
    scrollbar-color: #888 #e0e0e0; 
  }
  
  .sectionContent2::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }
  
  .sectionContent2::-webkit-scrollbar-track {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }
  
  .sectionContent2::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 2px solid #e0e0e0; 
  }
  .sectionCards {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
  }
  .sectionCard {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    background-color: #297bca;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    // border: 0.2px solid #297bca;
  }
  .sectionCardVariant2 {
    background-color: #fff;
    color: #297bca;
  }
  .footer {
    border-top: 1px solid #000;
    padding-top: 1rem;
  }
  .buttons {
    position: sticky;
    display: flex;
    justify-content: space-around;
    gap: 4px;
    & > button {
      padding: 0.35rem 0.5rem;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      width:100%;
      cursor: pointer;
    }
  }

  .dangerButton {
    color: #fff;
    background-color: #ff5a4f;
    border: 2px solid #ff5a4f;
  
    text-align: center;
  
  
  }
  .dangerButtonAlt {
    background-color: #fff;
    color: #ff5a4f;
    border: 2px solid #ff5a4f;
  
    text-align: center;
  
  /* Regular/Text/TRegular3 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  }
  
  .primaryButton {
    border-radius: 4px;
  border: 2px solid var(--Foundation-State-State---Info, #297BCA);
  background: var(--Foundation-State-State---Info, #297BCA);
  
  color: var(--Foundation-Neutral-White, #FFF);
  text-align: center;
  
  /* Regular/Text/TRegular3 */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  
  }
  .primaryButtonAlt {
    border-radius: 4px;
  border: 2px solid var(--Foundation-State-State---Info, #297BCA);
  background: var(--Foundation-Neutral-White, #FFF);
  
  color: var(--Foundation-State-State---Info, #297BCA);
  text-align: center;
  
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  }
  