/* styles.module.scss */
.treeContainer {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .nodeBackground {
    transition: all 0.3s ease;
    
    &:hover {
      stroke: #4285F4;
      transform: scale(1.05);
    }
  }
  
  .nodeDetailsContainer {
    padding: 20px;
    
    h2 {
      border-bottom: 2px solid #1A73E8;
      padding-bottom: 10px;
      margin-bottom: 15px;
      color: #333;
    }
    
    .nodeDetailItem {
      margin-bottom: 10px;
      padding: 10px;
      background-color: #f4f4f4;
      border-radius: 4px;
      
      strong {
        color: #1A73E8;
        margin-right: 10px;
      }
    }
}